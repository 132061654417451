<template>
  <div v-if="amount" class="h-screen-main grid lg:grid-cols-2">
    <div class="grid content-center items-center gap-20">
      <div class="max-w-lg ml-48 grid gap-2">
        <svg
          width="24"
          height="20"
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1707 17.7287C10.3122 17.4051 10.3797 17.0597 10.3691 16.7126C10.3907 15.7508 10.6801 14.8087 11.2113 13.9704C11.7413 13.1335 12.4971 12.4268 13.4107 11.9137C13.7489 11.7525 14.0469 11.532 14.2865 11.2655C14.5261 10.9991 14.7023 10.6922 14.8046 10.3635C14.9068 10.0348 14.933 9.69104 14.8814 9.35309C14.8298 9.01514 14.7016 8.69 14.5045 8.39735C14.2412 8.00643 13.8623 7.68594 13.4107 7.47271C13.1518 7.31311 12.903 7.14123 12.6659 6.95708L12.0717 6.44016C10.9829 5.42325 10.3724 4.07531 10.3648 2.67182C10.376 2.32469 10.3089 1.97907 10.1674 1.65536C10.0259 1.33164 9.8128 1.03639 9.54077 0.787009C9.26995 0.537911 8.94542 0.339842 8.58654 0.204625C8.22767 0.0694077 7.84181 -0.000185034 7.45199 3.6948e-07C7.0624 0.000488489 6.67688 0.0703899 6.31823 0.20557C5.95958 0.340749 5.63508 0.538461 5.36395 0.787009C5.09239 1.03683 4.87965 1.33225 4.73816 1.65599C4.59667 1.97974 4.52928 2.32531 4.53993 2.67247C4.54628 3.12312 4.68599 3.56435 4.94503 3.95184C5.20317 4.33869 5.57173 4.6581 6.01342 4.87777C7.80182 5.88318 9.04621 7.80547 9.04621 9.6961C9.04179 11.0874 8.44287 12.4253 7.37054 13.4392L6.01778 14.5067C5.57631 14.7257 5.20758 15.0449 4.94866 15.4326C4.68902 15.8197 4.55011 16.2616 4.54356 16.7126C4.53244 17.0597 4.59961 17.4053 4.74113 17.729C4.88265 18.0527 5.09565 18.348 5.36758 18.5974C5.63845 18.8465 5.96299 19.0445 6.32186 19.1797C6.68072 19.3149 7.06656 19.3846 7.45636 19.3845C7.84607 19.3841 8.23173 19.3142 8.59052 19.179C8.9493 19.0438 9.27391 18.8461 9.54513 18.5974C9.81656 18.3477 10.0292 18.0524 10.1707 17.7287Z"
            fill="#1D4ED8"
          />
          <path
            d="M14.1212 4.1095C13.8005 3.68175 13.6296 3.1784 13.6296 2.66407C13.6301 1.9751 13.9373 1.31427 14.4841 0.825778C14.9597 0.400767 15.5868 0.136017 16.258 0.0769494C16.9292 0.0178822 17.6027 0.16818 18.1628 0.502058C18.6425 0.788239 19.0161 1.19432 19.2363 1.669C19.4564 2.14423 19.5139 2.6666 19.4015 3.17072C19.2891 3.67483 19.0117 4.13827 18.6042 4.50301C18.198 4.86674 17.6789 5.11467 17.1134 5.21514C16.5478 5.31561 15.9614 5.26407 15.4289 5.0671C14.8959 4.86993 14.4408 4.53666 14.1212 4.1095Z"
            fill="#1D4ED8"
          />
          <path
            d="M14.9249 14.55C15.4042 14.2644 15.9678 14.1119 16.5438 14.1119V14.1106C16.9267 14.111 17.3058 14.1786 17.6592 14.3094C18.0127 14.4402 18.3336 14.6318 18.6035 14.873C19.0806 15.2994 19.3773 15.8591 19.4436 16.4574C19.5098 17.0557 19.3415 17.656 18.9672 18.1568C18.6476 18.5845 18.1922 18.9182 17.6588 19.1157C17.1263 19.3126 16.5399 19.3642 15.9743 19.2637C15.4087 19.1632 14.8897 18.9153 14.4834 18.5516C14.0758 18.1868 13.7984 17.7232 13.6859 17.219C13.5735 16.7147 13.6311 16.1922 13.8514 15.7169C14.0716 15.2422 14.4451 14.8362 14.9249 14.55Z"
            fill="#1D4ED8"
          />
          <path
            d="M23.5091 8.24356C23.829 8.67116 23.9999 9.17366 24 9.6878C23.9995 10.3769 23.6923 11.0379 23.1454 11.5266C22.8754 11.7678 22.5545 11.9593 22.2011 12.0901C21.8476 12.221 21.4686 12.2886 21.0858 12.2891C20.5091 12.2891 19.9455 12.1366 19.4668 11.851C18.987 11.5648 18.6135 11.1587 18.3933 10.6841C18.173 10.2088 18.1154 9.68625 18.2279 9.18201C18.3403 8.67776 18.6177 8.21421 19.0253 7.84941C19.4317 7.48555 19.9508 7.23754 20.5166 7.13707C21.0823 7.03659 21.6689 7.08821 22.2014 7.28532C22.7345 7.48267 23.1896 7.81617 23.5091 8.24356Z"
            fill="#1D4ED8"
          />
          <path
            d="M1.29477 7.52569C1.77405 7.24009 2.33697 7.0876 2.91371 7.0876C3.29652 7.08832 3.67541 7.15609 4.0287 7.28703C4.382 7.41797 4.70277 7.60951 4.97267 7.8507C5.44949 8.27694 5.74608 8.83634 5.81233 9.43439C5.87859 10.0324 5.71045 10.6325 5.33631 11.1331C5.01679 11.5605 4.56168 11.894 4.02864 12.0914C3.49601 12.2874 2.90983 12.3384 2.3445 12.238C1.77916 12.1376 1.26014 11.8902 0.853301 11.5273C0.445802 11.1625 0.168479 10.6991 0.0560562 10.195C-0.0563662 9.69088 0.00110965 9.1685 0.221287 8.69328C0.441359 8.21838 0.814914 7.81207 1.29477 7.52569Z"
            fill="#1D4ED8"
          />
        </svg>
        <h2 class="text-gray-600" v-if="amount=='£100.00' || amount=='£100'"> Deposit for sale quote</h2>
        <h2 class="text-gray-600" v-if="amount=='£350.00' || amount=='£350'"> Deposit for purchase quote</h2>
        <h2 class="text-gray-600" v-if="amount=='£450.00' || amount=='£450'"> Deposit for sale & purchase quote</h2>
        <h1 class="lg:text-3xl text-2xl font-bold text-gray-900">
          {{ amount }}
        </h1>
      </div>
      <svg
        width="596"
        height="313"
        viewBox="0 0 596 313"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.6177 163.559C50.9498 162.838 52.9851 161.735 54.8508 160.336C55.0204 154.866 54.0028 148.845 52.8579 142.4C52.5611 140.619 52.2643 138.838 51.9675 137.015H51.9251C47.5576 145.538 43.6142 154.696 40.1372 164.534C40.1372 164.534 40.1372 164.534 40.1372 164.576C43.1902 164.703 45.9887 164.364 48.6177 163.559Z"
          fill="#FFF0F0"
        />
        <path
          d="M50.1865 174.455C52.6458 171.36 53.9603 167.883 54.5115 164.152C54.5115 164.152 54.5115 164.109 54.4691 164.152C52.9426 165.042 51.2889 165.805 49.508 166.357C47.0487 167.12 44.4621 167.501 41.7484 167.501C40.9003 167.501 40.0099 167.459 39.1618 167.374C37.3809 172.59 35.7697 178.017 34.2432 183.614C34.2432 183.614 34.2432 183.657 34.2432 183.614C41.3244 182.003 46.6671 178.908 50.1865 174.455Z"
          fill="#FFF0F0"
        />
        <path
          d="M374.904 233.734C396.826 244.759 422.183 249.126 452.458 247.133C481.249 245.225 513.814 237.55 551.764 223.727C558.252 163.77 556.132 115.898 539.51 87.1914C537.729 87.2338 535.991 87.2762 534.21 87.2762C516.316 87.2762 498.295 84.7745 480.486 79.8134C467.892 76.294 455.384 71.5874 443.172 65.6934C428.077 73.1986 417.646 86.5978 408.656 102.075C402.296 113.014 393.137 120.774 382.791 125.354C380.798 138.583 378.466 149.141 375.837 156.901C372.063 168.053 367.653 173.777 362.353 174.371C362.056 174.413 361.759 174.413 361.42 174.413C354.932 174.413 347.894 166.654 339.964 150.71C335.979 142.696 331.738 132.477 327.244 120.181C321.774 116.407 316.855 111.53 312.742 105.594C301.845 89.8628 290.354 74.0891 278.778 60.6899H278.735C279.668 72.7322 281.746 84.5625 284.926 95.9263C305.703 169.494 334.282 213.296 374.904 233.734Z"
          fill="#FFF0F0"
        />
        <path
          d="M537.687 84.2227C537.687 84.2227 537.729 84.2227 537.687 84.2227C522.549 60.647 496.472 51.4457 455.808 61.071C452.585 61.8343 449.532 62.8095 446.691 64.0392V64.0816C458.013 69.3819 469.631 73.7069 481.249 76.9295C499.949 82.145 518.903 84.5619 537.687 84.2227Z"
          fill="#FFF0F0"
        />
        <path
          d="M361.972 171.402C367.102 170.809 374.014 162.158 379.569 126.625C379.569 126.625 379.569 126.583 379.569 126.625C363.964 132.392 346.198 131.162 331.442 122.767H331.399C335.343 133.367 339.117 142.229 342.636 149.353C352.007 168.307 358.325 171.784 361.972 171.402Z"
          fill="#FFF0F0"
        />
        <path
          d="M492.444 312.433L533.744 312.475C536.797 303.571 539.468 294.327 541.8 284.701C541.8 284.701 541.8 284.659 541.8 284.701C527.002 287.966 513.009 295.005 500.034 305.648C497.278 307.853 494.734 310.143 492.444 312.433C492.444 312.433 492.444 312.39 492.444 312.433Z"
          fill="#FFF0F0"
        />
        <path
          d="M479.426 273.677C496.43 262.949 515.426 256.462 535.991 254.384C539.935 254.002 543.921 253.748 547.906 253.705C548.246 251.458 548.585 249.211 548.882 246.921C549.814 240.179 550.62 233.564 551.341 227.077C513.73 240.645 481.335 248.235 452.628 250.144C447.794 250.483 443.088 250.61 438.466 250.61C413.788 250.61 392.417 245.946 373.548 236.448C351.88 225.55 333.859 208.462 318.509 184.25C304.22 161.777 292.305 133.155 282.043 96.7741C278.439 83.9686 276.234 70.6118 275.471 57.0007C256.856 36.1387 238.284 22.0611 222.171 24.7749C160.56 35.1634 92.6318 61.7921 54.3001 132.519C54.7241 135.742 55.2753 138.88 55.7842 141.89C56.717 147.318 57.6075 152.618 57.7771 157.664C57.7771 157.664 57.7771 157.664 57.8195 157.664C62.3565 152.872 65.4519 146.003 68.8865 138.498C74.102 127.049 79.9959 114.117 92.6318 104.746C103.36 96.8165 117.225 92.8731 133.847 93.0003C134.653 93.0003 135.373 93.6363 135.416 94.442C135.416 95.29 134.737 95.9685 133.932 95.9685C117.946 95.7989 104.674 99.5303 94.4551 107.12C82.4129 116.025 76.7309 128.576 71.6427 139.685C67.7416 148.251 64.0102 156.477 57.8195 161.777C57.4803 167.035 56.0386 171.911 52.604 176.236C48.4061 181.537 42.0034 185.098 33.5229 186.879C28.053 208.504 24.6608 232.928 23.8128 260.49C23.2615 279.062 25.0848 296.277 28.9858 312.094H271.528L440.162 312.263C448.6 299.373 461.193 285.168 479.426 273.677Z"
          fill="#FFF0F0"
        />
        <path
          d="M498.126 303.316C511.864 292.037 526.79 284.702 542.563 281.437C544.429 273.423 546.083 265.154 547.44 256.674C543.708 256.759 539.977 256.971 536.288 257.352C516.189 259.388 497.617 265.748 480.995 276.221C463.949 286.949 451.95 300.179 443.639 312.391V312.433L488.161 312.475C491.129 309.422 494.437 306.369 498.126 303.316Z"
          fill="#FFF0F0"
        />
        <path
          d="M278.269 43.8986C278.269 43.8986 278.269 43.941 278.269 43.8986C271.867 3.48907 241.718 -3.67694 225.224 1.75057C217.21 -1.2176 199.613 -3.50733 182.482 22.2309C160.051 55.856 161.027 70.3577 140.716 66.4566C140.716 66.4566 106.158 53.6511 79.2749 74.6827C79.2749 74.6827 99.5433 63.5733 119.939 67.3895C105.395 73.1986 94.3278 89.0995 93.4373 104.492C91.9956 130.018 107.685 133.707 107.685 133.707L194.779 138.88C194.779 138.88 270.722 158.258 278.778 74.2587C278.99 72.2658 279.117 70.3152 279.202 68.4071C280.008 100.633 276.997 125.226 258.595 137.226C258.595 137.226 294.509 121.749 278.269 43.8986Z"
          fill="#0B1E5B"
        />
        <path
          d="M222.553 75.615L223.867 64.8024C223.867 64.8024 240.489 68.8306 242.821 50.9368C245.153 33.0006 241.464 3.57331 225.224 8.704C209.026 13.7923 211.443 34.9511 207.203 60.8165C206.736 63.6151 205.804 68.4066 204.956 72.8588C203.853 78.3711 208.093 83.5018 213.69 83.5018C218.227 83.4594 222.044 80.0672 222.553 75.615Z"
          fill="#FF9494"
        />
        <path
          d="M240.319 32.6621C240.319 32.6621 239.174 31.4748 236.121 33.0013"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M229.718 7.89841C229.718 7.89841 249.944 8.49204 250.665 31.6862L229.379 30.9229L227.089 24.8594C227.089 24.8594 223.145 37.7497 214.919 37.4529C214.919 37.4529 197.789 38.5977 195.287 29.2692C192.785 19.9407 214.156 2.72532 229.718 7.89841Z"
          fill="#0B1E5B"
        />
        <path
          d="M212.757 51.9971C212.757 51.9971 216.319 63.6577 223.867 64.7602V67.8132L223.231 70.0605C223.231 70.0605 211.74 67.7708 212.757 51.9971Z"
          fill="#0B1E5B"
        />
        <path
          d="M224.46 35.545C224.46 35.545 226.75 33.2553 229.803 34.0186"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M234.976 34.6968L239.513 45.3398L235.739 46.8663"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="239.131" cy="36.5626" r="1.27207" fill="#0B1E5B" />
        <circle cx="228.785" cy="37.4533" r="1.48408" fill="#0B1E5B" />
        <circle cx="205.846" cy="40.3785" r="9.49814" fill="#FF9494" />
        <path
          d="M200.927 37.7495C200.927 37.7495 205.506 32.4068 210.043 37.7495"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M205.465 41.5234C205.465 41.5234 204.362 37.7072 208.348 36.1807"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M225.436 53.5659C225.436 55.7708 227.217 57.5517 229.422 57.5517C231.627 57.5517 233.408 55.7708 233.408 53.5659C233.408 51.361 231.627 53.5659 229.422 53.5659C227.217 53.5659 225.436 51.361 225.436 53.5659Z"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M386.82 240.985C376.261 240.985 368.883 245.904 368.883 245.904L311.725 148.803L268.898 147.912L303.16 311.246H386.82C406.24 311.246 421.929 295.515 421.929 276.137C421.929 256.674 406.621 240.985 386.82 240.985Z"
          fill="#FF9494"
        />
        <path
          d="M220.051 72.5626L273.817 99.8698C281.789 107.46 293.026 116.11 311.98 149.777L114.936 144.647C122.356 121.113 131.642 103.05 154.412 93.467L208.306 71.8418L220.051 72.5626Z"
          fill="#FF9494"
        />
        <path
          d="M218.525 83.206C218.525 83.206 211.655 85.6229 206.186 80.7891"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M357.18 237.805L363.795 235.557C367.654 234.243 369.223 229.663 367.018 226.229L340.516 185.734C302.481 115.516 273.266 96.1381 273.266 96.1381C216.277 118.399 141.818 92.6611 141.818 92.6611C141.818 92.6611 102.851 109.41 49.8052 230.511C47.5579 235.6 50.6957 241.451 56.208 242.426L122.78 254.087L113.282 311.458H123.882L299.004 311.245L298.835 258.879L357.18 237.805Z"
          fill="#7DACEF"
        />
        <path
          d="M116.292 293.183L115.996 294.836C149.451 299.755 183.246 302.214 217.168 302.214C244.517 302.214 271.824 300.603 298.877 297.423V295.727C271.824 298.949 244.517 300.561 217.168 300.561C183.331 300.561 149.621 298.101 116.292 293.183Z"
          fill="#BBD3F9"
        />
        <path
          d="M231.118 311.204L244.135 304.801L268.347 307.303C268.347 307.303 290.693 308.617 281.704 298.695"
          stroke="#0B1E5B"
          stroke-width="0.678889"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.7525 262.652C36.6607 285.507 53.4096 311.245 78.3846 311.245H344.29C351.668 311.245 353.576 306.835 350.608 304.97L329.534 289.026C319.654 281.564 308.206 276.348 296.036 273.974C274.369 269.733 272.46 275.5 248.588 275.797L92.8015 248.956L55.6994 242.384L46.7525 262.652Z"
          fill="#FF9494"
        />
        <path
          d="M301.21 310.736L292.517 304.842L266.058 289.747"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M323.556 310.736L310.623 299.288L279.118 282.369"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M298.708 275.84L342.298 307.133"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M148.137 258.073L244.136 274.695"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M105.819 264.518L136.603 256.462"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M287.174 184.039L300.022 228.604L298.708 265.197"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M293.576 184.039V206.343"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M150.045 191.035L136.603 253.621"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M158.864 195.19L145.635 211.727"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M466.366 164.703C466.366 149.141 453.773 136.548 438.211 136.548C427.441 136.548 418.113 142.611 413.363 151.473L355.781 259.6C353.152 264.518 351.668 270.115 351.668 276.094C351.668 295.514 367.399 311.203 386.777 311.203C401.618 311.203 414.339 301.96 419.469 288.942L464.374 175.007C465.688 171.827 466.366 168.35 466.366 164.703Z"
          fill="#FF9494"
        />
        <path
          d="M482.267 98.6392C482.055 93.8053 479.172 89.4803 474.805 87.445L467.003 85.2401C462.126 84.3496 458.395 84.0104 456.402 83.8832C455.427 83.7984 454.451 84.3072 453.985 85.1977C451.398 88.5899 453.519 92.1517 457.462 93.8053L464.034 96.5615L449.618 110.257L428.416 127.727C428.416 127.727 422.607 120.349 414 108.137C408.954 101.014 405.01 101.735 402.593 103.6C400.77 105 400.007 107.332 400.6 109.537C403.95 122.088 408.699 132.9 408.826 136.589L410.141 164.702C410.141 180.264 422.734 192.857 438.296 192.857C449.83 192.857 459.709 185.903 464.077 175.981H464.119L481.207 141.762C483.031 138.158 483.879 134.13 483.709 130.102L482.267 98.6392Z"
          fill="#FF9494"
        />
        <path
          d="M430.834 149.269L427.696 129.085"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M465.561 94.9082L448.218 111.657C448.218 111.657 448.176 117.212 452.501 117.085"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M467.002 109.877L447.794 121.368C447.794 121.368 445.25 129.976 454.791 129.085"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M467.639 126.965L451.526 133.071C451.526 133.071 450.593 142.569 462.254 140.915"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M470.437 93.7637L479.299 97.0287"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M447.497 136.294L429.561 141.085"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M451.569 79.6863L457.675 100.421C458.268 102.414 457.123 104.534 455.13 105.128L415.06 117C413.067 117.594 410.947 116.449 410.354 114.456L404.248 93.7215C403.654 91.7286 404.799 89.6084 406.792 89.0148L446.862 77.1421C448.855 76.5485 450.975 77.6934 451.569 79.6863Z"
          fill="#0B1E5B"
        />
        <path
          d="M422.692 111.106L414.296 113.608C413.999 113.692 413.745 113.523 413.66 113.268L413.406 112.336C413.321 112.039 413.491 111.784 413.745 111.7L422.141 109.198C422.438 109.113 422.692 109.283 422.777 109.537L423.031 110.47C423.158 110.724 422.989 111.021 422.692 111.106Z"
          fill="white"
        />
        <path
          d="M429.137 101.099L424.77 102.371C424.473 102.456 424.219 102.286 424.134 102.032L423.879 101.099C423.795 100.802 423.964 100.548 424.219 100.463L428.586 99.191C428.883 99.1062 429.137 99.2758 429.222 99.5302L429.477 100.463C429.604 100.717 429.434 101.014 429.137 101.099Z"
          fill="white"
        />
        <path
          d="M441.858 97.3257L437.491 98.5977C437.194 98.6826 436.939 98.5129 436.855 98.2585L436.6 97.3257C436.515 97.0289 436.685 96.7744 436.939 96.6896L441.307 95.4176C441.604 95.3328 441.858 95.5024 441.943 95.7568L442.197 96.6896C442.282 96.9441 442.112 97.2409 441.858 97.3257Z"
          fill="white"
        />
        <path
          d="M416.459 104.831L412.092 106.103C411.795 106.187 411.54 106.018 411.456 105.763L411.201 104.831C411.116 104.534 411.286 104.279 411.54 104.195L415.908 102.922C416.205 102.838 416.459 103.007 416.544 103.262L416.798 104.195C416.883 104.491 416.714 104.788 416.459 104.831Z"
          fill="white"
        />
        <path
          d="M452.925 102.456L446.989 104.194C446.395 104.364 445.759 104.025 445.589 103.431L444.996 101.438C444.826 100.845 445.165 100.209 445.759 100.039L451.695 98.3005C452.289 98.1309 452.925 98.4701 453.095 99.0637L453.688 101.057C453.858 101.65 453.519 102.244 452.925 102.456Z"
          fill="white"
        />
        <path
          d="M405.349 97.4949L452.671 83.3749L451.483 79.3467L404.205 93.5515L405.349 97.4949Z"
          fill="white"
        />
        <path
          d="M456.402 83.8832C455.426 83.7984 454.451 84.3072 453.985 85.1977C451.398 88.5899 453.518 92.1517 457.462 93.8053L467.002 85.2401C462.126 84.3496 458.394 84.0104 456.402 83.8832Z"
          fill="#FF9494"
        />
        <path
          d="M581.828 118.612C558.591 87.4882 511.694 83.2056 477.051 109.029C443.808 133.834 433.97 177.381 453.772 208.377L386.564 279.613L474.507 228.603C499.397 243.741 534.04 241.96 561.178 221.734C595.82 195.954 605.064 149.777 581.828 118.612Z"
          fill="white"
        />
        <path
          d="M386.608 279.656L474.55 228.646C501.646 244.123 534.507 241.706 561.221 221.777C595.864 195.911 605.107 149.735 581.871 118.612C558.634 87.4882 511.737 83.2056 477.095 109.029"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M533.744 122.301L495.582 132.393L478.621 170.512C473.236 182.639 474.508 194.13 482.183 201.805L493.377 213L514.536 201.805C528.995 194.173 540.529 182.682 545.956 170.512L562.917 132.393L533.744 122.301Z"
          fill="#7DACEF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M530.989 128.449L498 137.184L483.328 170.131C478.664 180.647 479.767 190.569 486.381 197.184L496.049 206.851L514.325 197.184C526.833 190.569 536.798 180.647 541.462 170.131L556.133 137.184L530.989 128.449Z"
          stroke="white"
          stroke-width="1.13148"
        />
        <path
          d="M502.367 162.201L506.098 171.106L532.345 156.011"
          stroke="white"
          stroke-width="5.43111"
        />
        <path
          d="M552.062 202.483L535.101 196.929"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M534.21 209.225L530.86 200.49"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M536.924 216.858L535.143 211.728"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M351.71 57.001L392.798 80.3223"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M356.586 95.0781L384.996 96.6046"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M401.533 48.8599L405.773 73.2836"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M398.522 27.6162L400.431 41.8634"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M334.325 94.9085L344.883 95.0781"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M541.334 311.753H1"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M396.742 311.753H291.414C289.082 311.753 287.174 309.845 287.174 307.513C287.174 305.181 289.082 303.273 291.414 303.273H323.301L337.505 271.301C338.481 269.097 340.643 267.697 343.018 267.697H409.971C412.515 267.697 414.254 270.284 413.236 272.658L396.742 311.753Z"
          fill="#0B1E5B"
        />
        <circle cx="368.035" cy="290.213" r="4.36745" fill="white" />
        <circle cx="36.9572" cy="235.175" r="32.8195" fill="#0B1E5B" />
        <path
          d="M38.441 283.047V300.559"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.2007 311.753L38.4408 300.559L58.1155 311.753H22.2007Z"
          fill="#0B1E5B"
        />
        <path
          d="M60.7867 194.087C83.472 207.232 91.2317 236.277 78.0869 259.005C64.9422 281.69 36.0661 289.535 13.3809 276.39"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.69287 282.708L63.4167 189.041"
          stroke="#0B1E5B"
          stroke-width="1.13148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.5301 263.033C18.8941 262.142 18.3005 261.209 17.7492 260.234C7.78466 242.934 13.721 220.8 31.0212 210.835C37.7208 206.977 45.1412 205.493 52.3072 206.129"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
        <path
          d="M54.3845 207.359C55.0205 208.25 55.6141 209.183 56.1654 210.158C66.1299 227.458 60.1936 249.55 42.8934 259.514C36.1938 263.373 28.7734 264.857 21.6074 264.221"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
        <path
          d="M53.2822 206.723L20.6323 263.669"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
        <path
          d="M28.3916 203.5L68.6739 226.737"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
        <path
          d="M5.24023 243.655L45.5225 266.892"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
        <path
          d="M8.54785 218.723L65.3671 251.669"
          stroke="white"
          stroke-width="0.905186"
          stroke-linecap="round"
        />
      </svg>
    </div>
    <div class="grid place-items-center shadow-lg">
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          px-4
          pt-5
          pb-4
          text-left
          overflow-hidden
          shadow-xl
          sm:my-8
          sm:align-middle
          sm:max-w-sm
          sm:w-full
          sm:p-6
        "
      >
        <div>
          <div
            class="
              mx-auto
              flex
              items-center
              justify-center
              h-12
              w-12
              rounded-full
              bg-green-100
            "
          >
            <svg
              class="h-6 w-6 text-green-600"
              x-description="Heroicon name: outline/check"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              Payment successful
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                Let the journey begin! Check your email for confirmation and
                further instructions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { retrievePaymentInformation } from "../../../services/payment";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const amount = ref("");
    onMounted(async () => {
      try {
        const { data } = await retrievePaymentInformation(
          route.query.session_id
        );
        amount.value = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(data.amount_total / 100);
      } catch (e) {
        debugger; // eslint-disable-line no-debugger

        router.push("/settings");
      }
    });
    return {
      amount,
    };
  },
};
</script>